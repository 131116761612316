<template>
    <div class="catalog-preview-photo__container">
        <div 
            class="catalog-preview-photo"
            @click="onClickPhotoMiddle"
        >
            <BasePreloaderSkeleton v-if="isLoading"/>

            <img
                v-if="photoDataMiddle"
                :src="photoDataMiddle"
                class="catalog-preview-photo__img"
            >
        </div>

        <AppPopup
			v-if="isPhotoBig"
			@close="onClosePhotoBig"
		>
			<div class="catalog-preview-photo__popup">
				<img
                    v-if="photoDataBig"
                    :src="photoDataBig" 
                    class="catalog-preview-photo__img_big"
                >
			</div>
		</AppPopup>
    </div>
</template>

<script>
import BasePreloaderSkeleton from '../Base/BasePreloaderSkeleton.vue';
import AppPopup from '@/components/Popup'
export default {
    name: 'CatalogPreviewPhoto',
    components: { 
        BasePreloaderSkeleton,
        AppPopup
    },
    props: {
        photoData: {
            type: Object,
            default: () => ({})
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        isPhotoBig: false
    }),
    computed: {
        photoDataMiddle() {
            return String(this.photoData?.middle ?? '');
        },
        photoDataBig() {
            return String(this.photoData?.big ?? '');
        }
    },
    methods: {
        onClickPhotoMiddle() {
            this.isPhotoBig = true;
        },
        onClosePhotoBig() {
            this.isPhotoBig = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .catalog-preview-photo {
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;

        &__popup {
            width: 832px;
            height: 632px;
            position: relative;
        }

        &__img {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
            border-radius: 10px;

            &_big {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
</style>