import ServiceApi from "@/services/ServiceApi";

/** Сервис для получения инфы о конструкции */
export default class ServiceConstruction {

    static _microserviceName = 'free';

    static _requestRouts = {
        _getInfo: '/catalog-info/'
    };

    static _catalogPreviewDataScheme = [
        {
            id: 'supplier',
            label: 'Подрядчик',
            value: '',
            type: 'general'
        },
        {
            id: 'oohdeskId',
            label: 'Oohdesk ID',
            value: '',
            type: 'general'
        },
        {
            id: 'address',
            label: 'Адресс',
            value: '',
            type: 'general'
        },
        {
            id: 'typeId',
            label: 'Тип',
            value: '',
            type: 'extra'
        },
        {
            id: 'kindId',
            label: 'Вид',
            value: '',
            type: 'extra'
        },
        {
            id: 'kindCodeId',
            label: 'Формат',
            value: '',
            type: 'extra'
        },
        {
            id: 'formatId',
            label: 'Размер',
            value: '',
            type: 'extra'
        },
        {
            id: 'code',
            label: 'Сторона',
            value: '',
            type: 'extra'
        },
        {
            id: 'light',
            label: 'Освещение',
            value: '',
            type: 'extra'
        },
        {
            id: 'materialId',
            label: 'Материал',
            value: '',
            type: 'extra'
        },
        {
            id: 'grp',
            label: 'GRP',
            value: '',
            type: 'extra'
        },
        {
            id: 'ots',
            label: 'OTS',
            value: '',
            type: 'extra'
        },
        {
            id: 'comment',
            label: 'Комментарий',
            value: '',
            type: 'extra'
        }
    ];

    static _getCatalogPreviewDataValueScheme = {
        photoData: (catalogData) => ({ middle: String(catalogData?.photo?.middle ?? ''), big: String(catalogData?.photo?.big ?? '') }),
        supplier: (catalogData) => String(catalogData?.supplier_id ?? ''),
        oohdeskId: (catalogData) => String(catalogData?.oohdesk_id ?? ''),
        address: (catalogData) => String(catalogData?.address ?? ''),
        typeId: (catalogData) => String(catalogData?.type_id ?? ''),
        kindId: (catalogData) => String(catalogData?.kind_id ?? ''),
        kindCodeId: (catalogData) => String(catalogData?.kind_code_id ?? ''),
        formatId: (catalogData) => String(catalogData?.format_id ?? ''),
        code: (catalogData) => String(catalogData?.code ?? ''),
        light: (catalogData) => parseInt(catalogData?.light ?? 0) === 0 ? 'Нет' : 'Да',
        materialId: (catalogData) => String(catalogData?.material_id ?? ''),
        grp: (catalogData) => parseFloat(catalogData?.grp ?? 0),
        ots: (catalogData) => parseFloat(catalogData?.ots ?? 0),
        comment: (catalogData) => String(catalogData?.comment ?? ''),
        marker: (catalogData) => ({ 
            lat: String(catalogData?.lat ?? ''), 
            lng: String(catalogData?.lng ?? ''),
            direction: String(catalogData?.direction ?? ''),
            fill_color: "#3600ff",
            id: "65058",
            additionalId: '34',
            sidesColors: { [catalogData?.code ?? 'A']: '#3600ff' },
            selected: false,
            iconType: 'directionAngleView'
        })
    }

    /**
     * Получить инфо о конструкции
     * @param {String} oohdeskId
     * @param {Function} actionAfter
     * @returns {Promise<void>}
     * @private
     */
    static async getInfoByOohdeskId(oohdeskId, actionAfter = () => {}) {
        try {
            const response = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts._getInfo + oohdeskId
            );

            const {data: responseData = {} } = response;
            const {data = {}} = responseData;
            actionAfter(data);

            return data;
        } catch (error) {
            console.log(error);
        }
    }

    static async getCatalogPreviewData(code = '') {
        return this._getCatalogPreviewData(code);
    }

    static async _getCatalogPreviewData(code = '') {
        const responseData = await this.getInfoByOohdeskId(code);
        const catalogPreviewData = this._getCatalogPreviewDataAfter(responseData);

        return catalogPreviewData;
    }

    static _getCatalogPreviewDataAfter(responseData = {}) {
        const catalogPreviewData = {
            marker: this._getCatalogPreviewDataValueScheme.marker(responseData),
            photoData: this._getCatalogPreviewDataValueScheme.photoData(responseData),
            infoData: this._catalogPreviewDataScheme.map(catalogItem => ({ ...catalogItem, value: this._getCatalogPreviewDataValueScheme[catalogItem.id](responseData) })),
        };

        return catalogPreviewData;
    }

}
