<template>
    <div class="page-catalog-preview">
        <div class="page-catalog-preview__info">
            <CatalogPreviewPhoto
                :photoData="catalogPreviewDataPhotoData"
                :isLoading="isLoading"
                class="page-catalog-preview__info-item"
            />

            <CatalogPreviewInfo
                :infoData="catalogPreviewDataInfoData"
                :isLoading="isLoading"
                class="page-catalog-preview__info-item"
            />
        </div>

        <div class="page-catalog-preview__map-container">
            <div class="page-catalog-preview__map-item">
                <LinceMapView
                    ref="LinceMapViewZoomed"
                    :renderDomElementId="'map-wrapper__zoomed'"
                    :isLegendPanel="false"
                    :isControlPanel="false"
                    :isToolbar="false"
                />
            </div>

            <div
                v-if="isMapScaled"
                class="page-catalog-preview__map-item"
            >
                <LinceMapView
                    ref="LinceMapViewScaled"
                    :renderDomElementId="'map-wrapper__scaled'"
                    :isLegendPanel="false"
                    :isControlPanel="false"
                    :isToolbar="false"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CatalogPreviewPhoto from '../components/CatalogPreview/CatalogPreviewPhoto.vue';
import CatalogPreviewInfo from '../components/CatalogPreview/CatalogPreviewInfo.vue';
import LinceMapView from '../components/MapV3/LinceMapView.vue';
import ServiceConstruction from '../services/ServicesConstruction/ServiceConstruction.js';
export default {
    name: 'PageCatalogPreview',
    components: {
        CatalogPreviewPhoto,
        CatalogPreviewInfo,
        LinceMapView
    },
    props: {
        code: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        isMapScaled: false,
        catalogPreviewData: {},
        isLoading: false
    }),
    computed: {
        catalogPreviewDataPhotoData() {
            return this.catalogPreviewData?.photoData ?? {};
        },
        catalogPreviewDataInfoData() {
            return this.catalogPreviewData?.infoData ?? [];
        },
        catalogPreviewDataMarker() {
            return this.catalogPreviewData?.marker ?? {};
        }
    },
    mounted() {
        this.loadMapScaled();
        this.getCatalogPreviewData();
    },
    methods: {
        loadMapScaled() {
            setTimeout(() => this.isMapScaled = true, 300);
        },
        async getCatalogPreviewData() {
            this.isLoading = true;
            
            this.catalogPreviewData = await ServiceConstruction.getCatalogPreviewData(this.code);

            setTimeout(() => {
                this.$refs.LinceMapViewZoomed.redrawMarkers(() => true, [this.catalogPreviewDataMarker], true, false);
                this.$refs.LinceMapViewScaled.redrawMarkers(() => true, [this.catalogPreviewDataMarker], true, false);
    
                this.$refs.LinceMapViewZoomed.focusMap(String(this.catalogPreviewDataMarker?.lat ?? ''), String(this.catalogPreviewDataMarker?.lng ?? ''), 8);
                this.$refs.LinceMapViewScaled.focusMap(String(this.catalogPreviewDataMarker?.lat ?? ''), String(this.catalogPreviewDataMarker?.lng ?? ''), 14);
            }, 300);
            
            this.isLoading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .page-catalog-preview {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 20px;
        background-color: #ecf0f2;
        
        &__info {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding-right: 20px;

            &-item {
                height: 50%;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }

        &__map {
            &-container {
                display: flex;
                width: 50%;
            }
    
            &-item {
                width: 50%;
                height: 100%;
                position: relative;
                &:not(:last-child) {
                    padding-right: 20px;
                }

                ::v-deep .map-wrapper__map {
                    border-radius: 10px;
                }
            }
        }

    }
</style>