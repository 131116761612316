<template>
    <div class="catalog-preview-info">
        <div class="catalog-preview-info__general">
            <div
                v-for="infoDataGeneralItem in infoDataGeneral"
                :key="infoDataGeneralItem.id"
                class="catalog-preview-info__general-item"
            >
                <BasePreloaderSkeleton v-if="isLoading"/>
                {{ infoDataGeneralItem.value }}
            </div>
        </div>

        <div class="catalog-preview-info__extra">
            <div
                v-for="infoDataExtraItem in infoDataExtra"
                :key="infoDataExtraItem.id"
                class="catalog-preview-info__extra-item"
            >
                <BasePreloaderSkeleton v-if="isLoading"/>
                <div class="catalog-preview-info__extra-item-label">
                    {{ infoDataExtraItem.label }}
                </div>

                <div class="catalog-preview-info__extra-item-value">
                    {{ infoDataExtraItem.value }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BasePreloaderSkeleton from '../Base/BasePreloaderSkeleton.vue';
export default {
    name: 'CatalogPreviewInfo',
    components: { BasePreloaderSkeleton },
    props: {
        infoData: {
            type: Array,
            default: () => ([])
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        infoDataLoading: [
            {
                id: 'supplier',
                label: 'Подрядчик',
                value: 'wefwefwefwefwefwef',
                type: 'general'
            },
            {
                id: 'oohdeskId',
                label: 'Oohdesk ID',
                value: 'wefwefwefsfgdge',
                type: 'general'
            },
            {
                id: 'address',
                label: 'Адресс',
                value: 'egswgwegrwgwrfewfwefrfrwge',
                type: 'general'
            },
            {
                id: 'typeId',
                label: 'Тип',
                value: 'gegwegwerg',
                type: 'extra'
            },
            {
                id: 'code',
                label: 'Сторона',
                value: 'B',
                type: 'extra'
            },
            {
                id: 'light',
                label: 'Освещение',
                value: 'Да',
                type: 'extra'
            }
        ]
    }),
    computed: {
        infoDataBuffer() {
            return this.isLoading ? this.infoDataLoading : this.infoData
        },
        infoDataGeneral() {
            return this.infoDataBuffer.filter(infoDataItem => String(infoDataItem?.type ?? '') === 'general');
        },
        infoDataExtra() {
            return this.infoDataBuffer.filter(infoDataItem => String(infoDataItem?.type ?? '') === 'extra');
        }
    }
}
</script>

<style lang="scss" scoped>
    .catalog-preview-info {
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: #fff;
        border-radius: 10px;

        &__general {
            margin-right: 20px;
            &-item {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 40px;
                }
            }
        }

        &__extra {
            width: 25%;
            &-item {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                text-align: left;
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                &-value {
                    text-align: right;
                }
            }
        }
    }
</style>